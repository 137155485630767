import _ from 'lodash'

export const parseCmsData = <T>(page: any): T => {
  const replaceAttributes = (obj: any) => {
    return _.transform(
      obj,
      function (result, value, key) {
        if (value === null) {
          return
        }

        // ignore image data null
        if (typeof value === 'object' && 'data' in value && value.data === null) {
          return
        }

        // if iamge data get attributes
        if (key === 'data') {
          if (value?.attributes) {
            value = value['attributes']
            key = 'attributes'
          }
        }

        // remove attributes and put it in the parent
        if (key === 'attributes') {
          Object.keys(value).forEach(key => {
            if (Array.isArray(value[key])) {
              result[key] = value[key].map((item: any) => replaceAttributes(item))
            } else if (typeof value[key] === 'object') {
              result[key] = replaceAttributes(value[key])
            } else {
              result[key] = value[key]
            }
          })
          return
        }

        if (Array.isArray(value)) {
          result[key] = value.map((item: any) => replaceAttributes(item))
          return
        }

        if (typeof value === 'object') {
          result[key] = replaceAttributes(value)
          return
        }

        result[key] = value
      },
      {} as any
    )
  }

  return replaceAttributes(page)
}
