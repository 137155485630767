import React from 'react'
import { BlocksRenderer, BlocksContent } from '@strapi/blocks-react-renderer'
import { Link } from 'gatsby'
import { H1, H2, H3, H4, H5, H6, Paragraph } from '~/styled/components/typography'

export interface BlockTextRenderProps {
  content: BlocksContent
}

const BlockTextRender: React.FC<BlockTextRenderProps> = ({ content }) => {
  if (!content) {
    return null
  }

  return (
    <BlocksRenderer
      content={content}
      blocks={{
        paragraph: ({ children }) => <Paragraph>{children}</Paragraph>,
        heading: ({ children, level }) => {
          switch (level) {
            case 1:
              return <H1>{children}</H1>
            case 2:
              return <H2>{children}</H2>
            case 3:
              return <H3>{children}</H3>
            case 4:
              return <H4>{children}</H4>
            case 5:
              return <H5>{children}</H5>
            case 6:
              return <H6>{children}</H6>
            default:
              return <H1>{children}</H1>
          }
        },
        link: ({ children, url }) => (
          <Link to={url} style={{ color: 'inherit' }}>
            {children}
          </Link>
        ),
      }}
    />
  )
}

export default BlockTextRender
